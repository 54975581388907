import {AnimatedIcon, Colors} from '@eitje/web_components'
import {useHover} from '@eitje/react-hooks'
import {Blob} from '../blob'
import {Image} from '../image'
import {Layout, getResp} from '../layout'
import {Text} from '../text'
import {Link} from '../link'
import {useI18n} from '/src/i18n'
import './styles.less'

export const PartnerCard = ({animation, item, spotlight, topText, button, style, to, onClick, ...rest}) => {
	const {hoverActions, isHovering} = useHover()
	const [t] = useI18n()

	const inlineStyles = {
		overflow: 'hidden',
		background: spotlight && Colors.lightBlue,
		flexShrink: 'unset',
		...style,
	}

	const bottomContent = button || (
		<Text bold small darkGrey={!topText}>
			{item?.partnerTypeNew?.name || t('common.miscellaneous')}
		</Text>
	)

	return (
		<Layout
			{...hoverActions}
			className="partner-card"
			relative
			vertical="center"
			border
			style={inlineStyles}
			horizontal="center"
			direction="vertical"
			hasHover
			parentGap={20}
			colorSet
			height={topText && 184}
			borderRadius="large"
			{...rest}
		>
			{spotlight && <Blob style={{zIndex: 0}} color="blue" number={6} width="100%" />}
			<Link
				undecorated
				to={to}
				onClick={onClick}
				style={{
					width: '100%',
					height: '100%',
					padding: getResp(['12px 16px', '24px 28px']),
					justifyContent: 'center',
					display: 'flex',
				}} // I made the link the full size of the parent. Making it the wrapper gave problems with the childrenPerRow logic
			>
				<Layout direction="vertical" horizontal="center" vertical="center" height="unset" gap={12}>
					{topText && <Text small bold={spotlight} darkGrey={!topText} semiBold={!spotlight} t={topText} />}
					{animation && (
						<AnimatedIcon
							size={60}
							animation={animation}
							animate={isHovering} // When we hover over the card, we animate the icon, even when the icon is not hovered
						/>
					)}
					{item?.logoBlock && <Image image={item.logoBlock} width={140} height={70} />}
					{bottomContent}
				</Layout>
			</Link>
		</Layout>
	)
}
