import React, {useState, useEffect} from 'react'
import useQueryParams from './use_query_params'
import _ from 'lodash'

export const getStoredQueryParams = () => {
	let _storedQueryParams = localStorage.getItem('queryParams') || {}
	if (_.isString(_storedQueryParams)) _storedQueryParams = JSON.parse(_storedQueryParams)
	return _storedQueryParams
}

export const useStoreOrQueryParams = () => {
	const [storedQueryParams, setStoredQueryParams] = useState({})
	const [loaded, setLoaded] = useState(false)
	useEffect(() => {
		setStoredQueryParams(getStoredQueryParams())
		setLoaded(true)
	}, [])

	const qp = useQueryParams()
	const combinedQueryParams = {...storedQueryParams, ...qp}
	return {queryParams: combinedQueryParams, loaded}
}

export default useStoreOrQueryParams
