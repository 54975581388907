import {EitjeButton} from '/src/components/eitje_button'
import {
	BecomePartnerButton,
	CallButton,
	ChatButton,
	IntegrationChatButton,
	IntegrationIndexButton,
	MailButton,
	PlanDemoButton,
	TeamButton,
	TryEitjeButton,
	Try28DaysButton,
	VideoButton,
} from '/src/components/eitje_button/buttons'

const tryEitjeButton = <TryEitjeButton />
const try28DaysButton = <Try28DaysButton />
const chatButton = <ChatButton />
const callButton = <CallButton />
const mailButton = <MailButton />
const videoButton = <VideoButton />
const demoButton = <PlanDemoButton />
const integrationChatButton = <IntegrationChatButton />
const integrationIndexButton = <IntegrationIndexButton />
const teamButton = <TeamButton />

const noSubtitle = {subtitle: false}

export const bannerMapping = (t, type, props) => {
	const {sector} = props || {}

	const instances = {
		sector_main: {
			titleLarge: t('banners.sector_main.title', {sector}),
			links: ['plan_demo', 'call', 'chat'],
			buttons: tryEitjeButton,
		},
		contact: {
			buttons: [chatButton, callButton, mailButton, videoButton],
		},
		contact_no_video_button: {
			title: 'banners.contact.title',
			subtitle: 'banners.contact.subtitle',
			buttons: [chatButton, callButton, mailButton],
		},
		contact_page: {
			titleLarge: true,
			buttons: [chatButton, callButton, mailButton],
		},
		app: {
			buttons: demoButton,
		},
		show_mobile_app: {
			buttons: <EitjeButton t="menu.mobile_app" link="/mobiele-app" />,
		},
		integrations: {
			titleLarge: true,
			buttons: integrationChatButton,
			links: ['become_partner'],
		},
		connect_with_your_software: {
			subtitle: 'banners.integrations_header.subtitle',
		},
		integrations_index: {
			title: 'banners.connect_with_your_software.title',
			subtitle: 'banners.integrations_header.subtitle',
			buttons: integrationIndexButton,
		},
		integrations_footer: {
			title: false,
			subtitle: false,
			buttons: integrationChatButton,
			links: ['integrations'],
		},
		try_for_free: {
			titleLarge: 'common.try_eitje_free',
			buttons: tryEitjeButton,
			links: ['plan_demo', 'call', 'chat'],
		},
		clock_without_startup_costs: {
			buttons: <EitjeButton link="/prijzen" t="links.prices" />,
		},
		'employee-planning-for-hospitality': {
			buttons: [try28DaysButton, demoButton],
		},
		'time-registration-clocking-system': {
			buttons: [try28DaysButton, demoButton],
		},
		'horeca-roster': {
			buttons: [try28DaysButton, demoButton],
		},
		'horeca-app': {
			buttons: [try28DaysButton, demoButton],
		},
		'seo-planning-page': {
			subtitle: false,
		},
		'seo-time-registration-page': {
			subtitle: false,
		},
		'seo-horeca-roster-page': {
			subtitle: false,
		},
		'seo-horeca-app-page': {
			subtitle: false,
		},
		we_are_eitje: {
			titleLarge: true,
		},
		our_story: {
			subtitle: false,
			paragraph: t('banners.our_story.paragraph'),
		},
		work_with_us: {
			buttons: <MailButton link="mailto:jobs@eitje.app" t="common.mail_us" />,
		},
		work_with_us_link: {
			title: 'banners.work_with_us.title',
			subtitle: 'banners.work_with_us.subtitle',
			buttons: <EitjeButton t="common.work_at_eitje" link="/vacatures" />,
		},
		pricing: {
			titleLarge: true,
			buttons: tryEitjeButton,
			links: ['chat', 'watch_all_features_pricing_page'],
		},
		features: {
			buttons: tryEitjeButton,
			links: ['watch_all_features_pricing_page', 'chat'],
		},
		become_partner: {
			titleLarge: true,
			buttons: <BecomePartnerButton t="common.know_more" />,
			links: ['chat', 'mail'],
		},
		become_partner_process: {
			titleLarge: true,
			buttons: <MailButton t="common.mail_us" />,
			links: ['see_partners', 'see_api_docs', 'chat'],
		},
		partner: {
			titleLarge: props.name,
			subtitle: false,
		},
		about_partner: {
			title: t('banners.about_partner.title', {partner: props.partner}),
			subtitle: false,
		},
		eitje_in_short: {
			subtitle: false,
			buttons: tryEitjeButton,
			links: ['chat', 'plan_demo'],
		},
		no_hidden_costs: {
			buttons: <EitjeButton link="/prijzen" t="common.see_savings" />,
		},
		best_support: {
			buttons: [chatButton, callButton],
			animation: 'trophy-number-one',
		},
		very_quick_support: {
			animation: 'trophy-number-one',
			buttons: <ChatButton t="common.try_chat" />,
		},
		other_planning_features: {
			buttons: <ChatButton t="links.chat" />,
		},
		other_hours_features: {
			buttons: <ChatButton t="links.chat" />,
		},
		discover_more_advantages: {
			subtitle: false,
			animation: 'binocular',
		},
		every_company_size: {
			buttons: <ChatButton t="common.chat_with_experts" />,
		},
		hours: {
			scrollLinks: ['connect_accounting_software', 'clock_system', 'automatic_balance', 'hours_in_app', 'analyse_hours'],
		},
		planning: {
			scrollLinks: ['repeat_planning', 'send_costs', 'fill_gaps', 'availability_deadline'],
		},
		communication: {
			scrollLinks: ['share_messages', 'reminders', 'eitje_chat', 'team_groupchat'],
		},
		onboarding: {
			scrollLinks: ['articles', 'automated_onboarding', 'skills', 'handbook'],
		},
		blog_post: {
			...props,
			titleLarge: props.title,
			buttons: <TryEitjeButton t="common.try_free_planning_tool" />,
			links: ['time_registration', 'communication', 'more_features'],
		},
		blog: {
			titleLarge: true,
			buttons: tryEitjeButton,
			links: ['chat'],
		},
		online_administration: {
			titleLarge: true,
			buttons: <EitjeButton t="links.all_features" link="/tools" />,
		},
		ei_curious: {
			buttons: [chatButton, callButton, mailButton, videoButton],
		},
		one_spot: {
			scrollLinks: ['planning', 'time_registration', 'clocking', 'communication'],
			titleLarge: true,
			...noSubtitle,
			buttons: [tryEitjeButton, demoButton],
		},
		video: {
			titleLarge: true,
			buttons: [tryEitjeButton, <EitjeButton t="links.all_features" link="/tools" />],
		},
		working_at_eitje: {
			titleLarge: true,
			buttons: [<MailButton link="mailto:jobs@eitje.app" t="common.mail_us" />, callButton, chatButton],
		},
		talk_to_you: {
			buttons: [<MailButton link="mailto:jobs@eitje.app" t="common.mail_us" />, callButton, chatButton, videoButton],
		},
		vacancy: {
			...props,
			title: props.name,
			subtitle: props.description,
			buttons: [mailButton, callButton, chatButton, teamButton],
		},
		homepage: {
			title: false,
			buttons: [tryEitjeButton, <PlanDemoButton t="common.online_appointment" />],
			links: ['try_28_days'],
		},
		privacy: {
			titleLarge: true,
		},
		conditions: {
			titleLarge: true,
		},
		eitje_video: {
			buttons: [<PlanDemoButton t="links.book_demo" iconLeft="calendar" />, callButton, mailButton, chatButton],
		},
		eitje_fit: {
			titleLarge: true,
			buttons: [<PlanDemoButton t="links.book_demo" />, tryEitjeButton],
		},
		all_features: {
			buttons: [
				<PlanDemoButton t="links.book_demo" iconLeft="calendar" />,
				<EitjeButton t="links.all_features" link="/tools" iconLeft="list" />,
			],
		},
		404: {
			animation: 'broken-egg',
			buttons: [<EitjeButton link="/" t="common.homepage" iconLeft="house" />, <ChatButton colorSet t="links.chat" />],
		},
		sector_advantages: {
			buttons: [callButton, chatButton],
		},
		hours_features: noSubtitle,
		discover_all_of_eitje: noSubtitle,
		join_many_companies: {
			...noSubtitle,
			buttons: tryEitjeButton,
		},
		perfect_your_planning: noSubtitle,
		about_integration: noSubtitle,
		open_vacancies: noSubtitle,
		our_philosophy: noSubtitle,
		future_colleagues: noSubtitle,
		eitje_for_everybody: noSubtitle,
		extensive_communication: noSubtitle,
		other_communication_features: noSubtitle,
		knowledge_base: noSubtitle,
		advantages_eitje: noSubtitle,
		financial_product_tiles: noSubtitle,
		clocking_product_tiles: noSubtitle,
	}
	return {...props, ...instances[type]}
}
