import React, {Children} from 'react'
import _ from 'lodash'
import utils from '@eitje/utils'
import {AnimatedIcon} from '@eitje/web_components'
import {getLinkFromMapping} from '../../helpers'
import {useI18n} from '/src/i18n'
import {Layout, getResp} from '../layout'
import {Link} from '../link'
import {Text, Title} from '../text'
import {DelimitedList} from '../delimited_list'
import {bannerMapping} from './instances'
import {RichText} from '../rich_text'
import {tablet} from '../../styles/breakpoints.module.less'
import {parseAndRenderLinks} from '../../helpers/parse_links'
import './styles.less'
import {ScrollToLinks} from '../scroll_to_links'

const inlineStyles = {flexWrap: 'wrap'}

export const Banner = props => {
	let {type, marginTop, test, margin, subtitle: subtitleByProp, children, gap = 20, ...rest} = props
	const [t] = useI18n()
	let {
		titleLarge,
		title = titleLarge || true,
		subtitle: subtitleByTax = true,
		links,
		buttons,
		paragraph,
		richText,
		animation,
		scrollLinks,
		children: taxChildren,
		maxWidth = tablet, // Only apply maxWidth to the text, since we want to keep our CPL (characters per line) sane.
		firstButtonSolid = true,
	} = bannerMapping(t, type, rest)

	// Some banners always have the same children (so far), so simply add them to the taxonomy to keep things DRY.
	children = children || taxChildren

	buttons = utils.alwaysDefinedArray(buttons)
	const hasButtons = !_.isEmpty(buttons)

	if (_.isBoolean(title) && title) title = `banners.${type}.title`

	let subtitle
	const showSubtitleByTax = _.isBoolean(subtitleByTax) && subtitleByTax
	const hideSubtitleByProp = subtitleByProp === false

	if (_.isString(subtitleByProp)) {
		subtitle = subtitleByProp
	} else if (showSubtitleByTax && !hideSubtitleByProp) {
		subtitle = `banners.${type}.subtitle`
	} else {
		subtitle = subtitleByTax
	}

	const formattedLinks = links && <DelimitedList elements={links.map(getLinkFromMapping)} Component={Link} />

	buttons = Children.map(buttons, (child, i) => {
		const singleButton = buttons.length === 1
		const isFirst = i === 0

		const childProps = {
			colorSet: isFirst && firstButtonSolid ? 'solid' : undefined, // undefined is needed to explicitely overwrite colorSet from the button
		}

		if (singleButton) {
			childProps.iconLeft = null
		}

		return React.cloneElement(child, childProps)
	})

	return (
		<Layout className="banner" margin={margin} marginTop={marginTop} direction="vertical" horizontal="center" gap={gap} width="100%">
			{animation && <AnimatedIcon size={100} animation={animation} />}
			{(title || subtitle) && (
				<Layout direction="vertical" horizontal="center" gap={8} maxWidth={maxWidth}>
					{title && <Title center size={titleLarge ? 'large' : 'regular'} t={title} />}
					{subtitle && <Title center mini darkGrey weight="regular" t={subtitle} />}
				</Layout>
			)}
			{scrollLinks?.length && <ScrollToLinks links={scrollLinks} />}
			{paragraph && (
				<Text className="banner-paragraph" fontSize={getResp([14, 18])} lineHeight={2.5}>
					{parseAndRenderLinks(paragraph, {
						fontSize: getResp([14, 20]),
					})}
				</Text>
			)}
			{richText && (
				<Layout className="banner-rich-text">
					<RichText content={richText} />
				</Layout>
			)}
			{children}
			{(hasButtons || formattedLinks) && (
				<Layout gap={[8, 12]} direction="vertical" horizontal="center" width="100%">
					{hasButtons && (
						<Layout width="100%" horizontal="center" style={inlineStyles} gap={12}>
							{buttons}
						</Layout>
					)}
					{formattedLinks}
				</Layout>
			)}
		</Layout>
	)
}
