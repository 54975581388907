import {Colors} from '@eitje/web_components'
import {useWindowWidth} from '/src/contexts'
import {Layout} from '../layout'
import {Image} from '../image'
import {Icon} from '../icon'
import './styles.less'

const dotColors = ['#FF0000', '#FDB600', '#0ADE5F']

const containerInlineStyles = {borderRadius: '12px', overflow: 'hidden'}
const inlineStyles = {background: Colors.lightBorder}

export const Browser = ({image, alt = 'Eitje dashboard preview', loading = 'lazy', ...rest}) => {
	const {desktop, mobile} = useWindowWidth()

	return (
		<Layout
			className="browser"
			direction="vertical"
			style={containerInlineStyles}
			border
			gap={0}
			maxWidth={desktop ? 660 : '100%'}
			{...rest}
		>
			<Layout padding={`0 ${mobile ? 8 : 16}`} height={[17, 27, 30]} style={inlineStyles} width="100%">
				<Layout gap={[3, 6]}>
					{dotColors.map(color => (
						<Icon name="dot" size={[5, 9, 10]} fill={color} />
					))}
				</Layout>
			</Layout>
			<Image image={image} className="browser-preview" alt={alt} loading={loading} />
		</Layout>
	)
}
