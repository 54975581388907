import {useLocation} from '@reach/router'
import {graphql, useStaticQuery} from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {useIsTranslationIgnored} from '../helpers/change_language'
import {useT} from '../i18n'

function _SEO({description, lang, meta, title}) {
	const {lng} = useT()
	const loc = useLocation()
	const isIgnored = useIsTranslationIgnored()
	const {site} = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					author
				}
			}
		}
	`)

	const metaDescription = description || site.siteMetadata.description

	const baseUrl = loc.pathname.replace('/en', '')
	const englishUrl = `https://www.eitje.app/en${baseUrl}`
	const dutchUrl = `https://www.eitje.app${baseUrl}`

	return (
		<Helmet
			htmlAttributes={{
				lang: lng,
			}}
			title={title}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		>
			<link rel="alternate" href={dutchUrl} hreflang="x-default" />
			{!isIgnored && <link rel="alternate" href={englishUrl} hreflang="en" />}
		</Helmet>
	)
}
_SEO.defaultProps = {
	lang: `nl`,
	meta: [],
	description: ``,
}
_SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
}
export default _SEO
export const SEO = _SEO
