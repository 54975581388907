const {WindowWidthProvider} = require('./src/contexts')

// Wrap your application with the WindowWidthProvider
exports.wrapRootElement = ({element}) => {
	return <WindowWidthProvider>{element}</WindowWidthProvider>
}

exports.onRouteUpdate = ({location, prevLocation}) => {
	// Ensure that when you navigate back (using the browser's back button or similar functionality) you maintain your current
	// locale, which is handled by the path. Specifically there where issues when the browser is in english and you would navigate
	// back to the root page (homepage).
	const {pathname: nextPath} = location
	const {pathname: prevPath} = prevLocation || {}

	const toHome = nextPath === '/'
	const homeLocaleSwtich = nextPath === '/' && prevPath === '/en/'
	const currentlyEnglish = prevPath?.includes('/en/')

	if (toHome && currentlyEnglish && !homeLocaleSwtich) {
		const correctedPath = '/en/'
		window.location.replace(correctedPath)
	}

	// Ensure immediate scroll to top, instead of a smooth scroll (which has a bug that it sometimes does not scroll up on route change)
	// Works together with the scroll-behaviour CSS property set on all html elements, in html_tags.less
	window.scrollTo(0, 0)
}
