import _ from 'lodash'

export const switchLanguageUrl = (lng, path) => {
	if (lng === 'en') return path.replace('/en', '')
	return `/en/${path}`.replace('//', '/')
}

export const isCurrentPage = link => {
	let url = typeof window !== 'undefined' && _.trimEnd(window.location.pathname, '/')
	if (!url) return
	return link === url.replace('/en', '')
}

export const isSubItemActive = items => items?.some(item => isCurrentPage(item.link))
