import {graphql} from 'gatsby'
import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {Helmet} from 'react-helmet'
import {Banner, Layout, PageLayout} from '/src/components'
import {useBodyHeight} from '/src/hooks/use_header_height'

const OFFSET = 140 // To vertically center the text we offset the animated icons height plus gap

const Page = ({data}) => {
	const {seo} = formatData({data})

	const bodyHeight = useBodyHeight()
	return (
		<>
			<Helmet>
				<link rel="canonical" href="" />
			</Helmet>
			<PageLayout name="404" hideFooter {...seo}>
				<Layout height={`calc(${bodyHeight} - ${OFFSET}px)`}>
					<Banner type="404" />
				</Layout>
			</PageLayout>
		</>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "404"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}
	}
`

export default withFlattenedResponse(Page)
