import {changeLocale} from 'gatsby-plugin-react-intl'
import {useLocation} from '@reach/router'

export const changeLanguage = languageCode => {
	if (window.Intercom) {
		window.Intercom('update', {language_override: languageCode})
	}
	changeLocale(languageCode)
}

const ignoredPaths = [
	'/blog*',
	'/blog/*',
	'/personeelsplanning-horeca/',
	'/urenregistratie-inkloksysteem/',
	'/horeca-rooster/',
	'/horeca-app/',
]

export const useIsTranslationIgnored = () => {
	const loc = useLocation()

	return ignoredPaths.some(pattern => {
		const regex = new RegExp('^' + pattern.replace(/\*/g, '.*') + '$')
		return regex.test(loc.pathname)
	})
}
