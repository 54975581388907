import {AnimatedIcon} from '@eitje/web_components'
import {useWindowWidth} from '/src/contexts'
import {Text} from '../text'
import {Layout} from '../layout'
import {Link} from '../link'
import {highlightElementMapping} from './instances'
import {useI18n} from '/src/i18n'
import {Fragment} from 'react'
import {getLinkFromMapping} from '../../helpers'
import './styles.less'
import _ from 'lodash'

export const PerksHighlight = ({highlights, marginTop}) => {
	const {mobile} = useWindowWidth()

	const elements = highlights.map(h => (_.isObject(h) ? h : {type: h})).map(highlightElementMapping)

	return (
		<Layout className="highlight-banner" horizontal="center" marginTop={marginTop}>
			<Layout
				direction={mobile && 'vertical'}
				vertical="start"
				gap={[20, 20, 40]}
				width={['100%', undefined]} // undefined is needed so that switching back to non mobile unsets the mobile width
			>
				{elements.map(e => (
					<Perk {...e} />
				))}
			</Layout>
		</Layout>
	)
}

const Perk = props => {
	let {animation, link, onClick, type, hide_cta} = props
	const {mobile} = useWindowWidth()
	const [t] = useI18n()
	const title = t(`highlights.${type}.title`)
	const text = t(`highlights.${type}.text`)
	const renderLink = mobile && link && !hide_cta
	const Wrapper = renderLink ? Link : Fragment

	if (link) link = getLinkFromMapping(link)

	const onClickProps = onClick ? {link: null, onClick} : link
	const wrapperProps = renderLink && {...link, style: {textDecoration: 'none'}}

	return (
		<Wrapper {...wrapperProps}>
			<Layout direction={mobile ? 'horizontal' : 'vertical'} horizontal={!mobile && 'center'} gap={[26, 20]} width={['100%', 220]}>
				{animation && <AnimatedIcon size={mobile ? 56 : 72} animation={animation} />}

				<Layout direction="vertical" horizontal={!mobile && 'center'} grow gap={[4, 12]}>
					{title && <Text bold large center={!mobile} t={title} />}
					<Layout direction="vertical" gap={[0, 4]} horizontal={!mobile && 'center'}>
						{text && <Text darkGrey center={!mobile} t={text} />}
						{link && !hide_cta && <Link {...link} {...onClickProps} />}
					</Layout>
				</Layout>
			</Layout>
		</Wrapper>
	)
}
