import {Browser} from '.'
import {useWindowWidth} from '/src/contexts'
import {Blob} from '../blob'
import {Layout} from '../layout'

export const BrowserWithBlob = ({image, imageProps, number = 2, color = 'blue', position = 'left', ...rest}) => {
	const {mobile} = useWindowWidth()
	return (
		<Layout relative {...rest}>
			{!mobile && <Blob number={number} color={color} position={position} horizontalBlobOffset={-150} top={140} />}
			<Browser {...imageProps} image={image} />
		</Layout>
	)
}
