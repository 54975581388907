import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
import {PageLayout, ProductHighlights, PerksBanner, Banner, LogoBanner, FAQ} from '/src/components'

const PRODUCTS = [
	['seo_roster_planning_financial', 'seo_roster_open_shifts'],
	['seo_roster_app', 'seo_roster_availability'],
	['seo_roster_published_notification', 'seo_roster_communication'],
]

const PERKS = [
	{type: 'monthly', hide_cta: true},
	{type: 'lowest_price', hide_cta: true},
	{type: 'integrations', hide_cta: true},
]

const InnerPage = ({data}) => {
	const {faq, products} = formatData({data})
	const horecaLogos = data.sector.clients.map(client => client.logo)

	return (
		<>
			<ProductHighlights highlights={products} items={PRODUCTS[0]} coloringOffsetAmount={1} marginTop={100} />
			<PerksBanner type="no_hidden_costs" highlights={PERKS} marginTop={100} />
			<ProductHighlights highlights={products} items={PRODUCTS[1]} coloringOffsetAmount={3} marginTop={120} />
			<LogoBanner type="seo-horeca-roster-page" logos={horecaLogos} marginTop={120} />
			<ProductHighlights highlights={products} items={PRODUCTS[2]} marginTop={140} />
			<FAQ items={faq} marginTop={100} />
			<Banner type="try_for_free" marginTop={120} />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})
	const bannerProps = {titleLarge: true}
	const pageName = 'horeca-roster'

	return (
		<PageLayout name={pageName} banner={pageName} data={data} bannerProps={bannerProps} {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "horeca-roster"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		allContentfulFeatureHighlight(
			filter: {
				node_locale: {eq: $locale}
				uuid: {
					in: [
						"seo_roster_planning_financial"
						"seo_roster_open_shifts"
						"seo_roster_app"
						"seo_roster_availability"
						"seo_roster_published_notification"
						"seo_roster_communication"
					]
				}
			}
		) {
			edges {
				node {
					uuid
					cta
					name
					preview {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					label
					buttonLink
					buttonText
					paragraph {
						paragraph
					}
				}
			}
		}

		contentfulSector(node_locale: {eq: $locale}, slug: {eq: "horeca"}) {
			clients {
				logo {
					gatsbyImageData
				}
			}
		}

		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "horeca-roster-seo"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
