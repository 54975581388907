import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import './index.less'

export const Image = props => {
	let {image, children, width, height, style, borderRadius, ...rest} = props
	image = getImage(image)
	children = undefined // children is an empty array here, which we are not allowed to pass to GatsbyImage

	const styles = {
		...style,
		width,
		height,
		borderRadius,
	}

	if (!image) return null
	return <GatsbyImage image={image} style={styles} {...rest} />
}
